import React from "react";
import ResearchPage from "../pages/research";
import TeachingPage from "../pages/teaching";
import SkillsPage from "../pages/skillpage";
import { Box, Flex } from "rebass";
import Image from "../components/image";

import Layout from "../components/new_layout.jsx";

import SEO from "../components/seo";

const textSizes = ["18px", "18px", "18px"];
const headTextSizes = ["24px", "28px", "32px"];

function IndexPage() {
  return (
    <Layout navbar="initial">
      <SEO title="Hi! My name is Timur Abbiasov." />
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans&family=Share+Tech+Mono&family=Roboto&family=Roboto+Condensed&family=Source+Sans+Pro&display=swap"
        rel="stylesheet"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap"
        rel="stylesheet"
      ></link>
      <link href="https://fonts.googleapis.com/css2?family=Bungee&family=Share:wght@700&display=swap" rel="stylesheet"></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Oswald&display=swap"
        rel="stylesheet"
      ></link>

      <script src="https://use.fontawesome.com/7129732b1e.js"></script>

      {/* <Flex
        sx={{
          // minHeight:[200,200,230],
          justifyContent: "space-evenly",
          fontSize: textSizes,
          fontFamily: "Roboto Slab, serif",
          color: "#ffffff",
        //   bg: 'red',
          pb: 0,
          pl: ["0px", "2px", "6px"],
          mb: 0,
          flexDirection: "row",
          flexGrow: 1,
          pl: 0,
          mt: [0, 0, 2],
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      > */}
        {/* <Flex
          sx={{
            flexDirection: "column",
            maxWidth: ["40vw", "40vw", "50vw"],
            mr: 4,
            mt: [0, 0, 2],
            pt: 1,
            flexGrow: 1,
            // bg: "white",
            justifyContent: "space-between",
            "@media screen and (max-height: 600px)": {
              opacity: 1,
              maxWidth: "40vw",
            },
          }}
        > */}
          {/* <Box
            sx={{
              fontWeight: "bold",
              fontFamily: "Sourse Pro, sans-serif",
              fontSize: headTextSizes,
              mb: 3,
            }}
          >
            Hi!
          </Box> */}
          {/* <Box sx={{ mb: 2 }}>My name is Timur Abbiasov.</Box> */}
          {/* <Box sx={{ mb: 2 }}>
            Currently, I am a post-doc researcher at MIT Senseable City Lab.
          </Box> */}
        {/* </Flex> */}
        {/* <Box
          sx={{
            mt: 2,
            width: [220, 0, 0],
            pr: 3,
            bg: "white",
            mb: [0, 2, 2],
            "@media screen and (max-height: 600px)": {
              opacity: 1,
              width: [220, 220, 220],
            },
          }}
        >
          <Image
            src="/images/pic_vert_2000x2500.jpg"
            sx={{
              width: "100%",
            }}
          />
        </Box> */}
      {/* </Flex> */}
      <Flex
        sx={{
          flexDirection: "column",
          fontFamily: "Roboto Slab, serif",
          fontSize: textSizes,
          mt: [3, 4, 4],
          mb: [3,4,4],
          ml: [4,4,4],
          "@media screen and (max-height: 600px)": {
            mt: [3, 2, 2],
          },
          maxWidth: ["85vw", "60vw", "70vw"],
        }}
      >
        <Box sx={{ mb: 2 }}>
          I am a post-doc researcher at MIT Senseable City Lab. In my research, I use large-scale GPS datasets to study human mobility
          patterns in urban settings and explore new ways to empirically address
          the major challenges in urban policy and planning. 
        </Box>
      </Flex>
      <ResearchPage />
      <br />
      {/* <TeachingPage />
      <br /> */}
      {/* <SkillsPage /> */}
    </Layout>
  );
}
export default IndexPage;
